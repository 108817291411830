<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\servertasks\\edit.7279') }}</v-toolbar-title>
            </v-toolbar>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-select
                    v-model="entity.type"
                    :label="$t('src\\views\\servertasks\\edit.2688')"
                    :items="types"
                    item-text="title"
                    item-value="value"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            
            <v-text-field
                v-model="entity.name"
                :label="$t('src\\views\\servertasks\\edit.4645')"
                outlined
            />

            <v-row
                align="center"
                justify="space-between"
                class="mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\servertasks\\edit.4252') }}</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\servertasks\\edit.3777') }}</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { SERVER_TASK_TYPE, SERVER_TASK_TYPE_TITLE } from '@/vars';

    export default {
        name: 'ServerTasksEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            SERVER_TASK_TYPE,
            SERVER_TASK_TYPE_TITLE,
            valid: true,
            activeDatePicker: false,
            types: []
        }),
        computed: {
            ...mapState('serverTasks', ['entity']),
        },
        async mounted() {
            for(const type in SERVER_TASK_TYPE) {
                this.types.push({
                    value: SERVER_TASK_TYPE[type],
                    title: this.$t(SERVER_TASK_TYPE_TITLE[SERVER_TASK_TYPE[type]])
                });
            }
            if(this.$route.params.id) {
                await store.dispatch('serverTasks/get', { id: this.$route.params.id });
            } else {
                await store.commit('serverTasks/CLEAR_ENTITY');
            }
        },
        methods: {
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('serverTasks/SET_ENTITY', this.entity);
                    await store.dispatch('serverTasks/save');
                    await this.$router.push({ name: 'server-tasks-list' });
                }
            },
            async remove() {
                await store.dispatch('serverTasks/delete', { id: this.entity._id });
                await this.$router.push({ name: 'server-tasks-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
</style>
